.userDetails {
    &__collapseHeaderCell {
        display: flex;
        justify-content: space-between;

        svg {
            font-size: 10px !important;
            border: 1.9px solid black;
            border-radius: 50%;
            padding: 1px;
        }
    }
    &__info {
        display: flex;
    }

    &__tableBody--collapse {
        display: none;
        transition: all 1s;
    }

    &__tableHeader {
        display: flex;
        align-items: center;

        svg {
            font-size: 18px;
            margin-right: 8px;
        }
    }

    &__footer {
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        background: white;
        margin-top: auto;
        display: flex;
        justify-content: flex-end;
        margin-right: 80px;
        outline: 1px solid #e0e0e0;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
}