.toolDetails {

    &__tool {
        display: flex;
    }

    &__toolName {
        font-size: 20 !important;
        line-height: 25px !important;
    }

    &__capability {
        font-size: 12px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-transform: uppercase;
        letter-spacing: 0.7px !important;
        max-width: 100%;

    }

    &__capabilityWrapper {
        display: flex;

        svg {
            margin-left: auto;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
    } 
}