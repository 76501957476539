$sizeSmall: 20px;
$size: 28px;
$sizeLarge: 40px;

.iconWrapper {

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 50%;
    outline: 1px solid #E0E0E0;
    min-width: $size;
    height: $size;

    img {

        width: $size;
        height: $size;
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%
    }
}

.iconWrapper__large {
    min-width: $sizeLarge;
    height: $sizeLarge;

    img {

        width: $sizeLarge;
        height: $sizeLarge;
    }
}
.iconWrapper__small {
    min-width: $sizeSmall;
    height: $sizeSmall;

    img {

        width: $sizeSmall;
        height: $sizeSmall;
    }
}