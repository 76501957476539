.badge {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Approved, .AutoApproved, .AddedToProject {
    background-color: #eaefe0;
    color: #7ab800;
}


.Denied {
    background-color: #fbe5e0;
    color: #e52700;
}