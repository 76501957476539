.faqArticle {
    margin-left: 80px;
    margin-top: 16px;
    width: 90%;
}


.faqArticleBar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}


.topBar {
    display: flex;
    width: 94%;
    justify-content: space-between;
}