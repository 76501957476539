.toolDetails {
    &__collapseHeaderCell {
        display: flex;
        justify-content: space-between;

        svg {
            font-size: 10px !important;
            border: 1.9px solid black;
            border-radius: 50%;
            padding: 1px;
        }
    }

    &__collapseCell {
        display: flex;
        justify-content: space-between;
        height: 74px !important;
        padding: 0;
        align-items: center;
    }

    &__tableBody--collapse {
        display: none;
        transition: all 1s;
    }

    &__tableHeader {
        display: flex;
        align-items: center;

        svg {
            font-size: 18px;
            margin-right: 8px;
        }
    }
}