.details {
    &__title {
        margin-bottom: 32px !important;
    }
}

.packageDetails {
    &__title {
        margin-bottom: 32px !important;
        padding-top:16px !important;
    }
}