.projectTypeSelection {
    margin-top: 16px;
    margin-bottom: 24px;

    &__radioGroup {
        margin-top: 8px;
        margin-bottom: 8px;

    }

    &__label {
        font-size: 12px !important;

        span {
            color: red;
        }
    }
}