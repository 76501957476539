.faqList {
    display: flex;
    padding-top: 24px;
    padding-left: 100px;
    &__container {
        width: 50%;
        margin-right: auto;
    }

    &__sideFilters {
        width: 420px;
        margin-right: 100px;

    }

   
}

.topBar {
    display: flex;
    width: 94%;
    justify-content: space-between;
}