.faqToolsFilter {
    background-color: white;

    &__header {
        padding: 16px;
        border-bottom: 1px solid #eee;
    }

    &__content {
        padding: 16px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        button {
            background-color: #ebf5eb;
        }

    }
}