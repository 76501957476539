.toolAccess {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  
  &__toolIcon {
    margin-top: 16px;
    margin-left: -10px;
  }

  &__toolName {
    margin-top: 16px;
    margin-left: 16px;
  }

  &__toolsTable{
    width: 100%;

    &___itemAlight{
       align-items: start;
      text-align: left;
    }
  }

}
