$size: 35px;

.additionalTools {

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 50%;
    padding: 3px;
    outline: 1px solid #e8e8e8;
    width: $size;
    height: $size;
    color: #9e9e9e;

    &:hover {
        cursor: default;
    }
}