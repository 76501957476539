#root {
  display: flex;
  height: 100%;
  flex-direction: row;
  margin: 0;
}

html {
  height: 100%;
  background-color: #f2f2f2;

}

body {
  height: 100%;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "HEINEKEN Core", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'HEINEKEN Core';
  font-weight: normal;
  font-style: normal;
  src: url('../../Common/assets/HEINEKEN__Core.otf') format('opentype');
}

@font-face {
  font-family: 'HEINEKEN Core-Bold';
  font-weight: 900;
  font-style: bold;
  font-size: 96px;
  src: url('../../Common/assets/HEINEKEN__Core-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Lucida Sans Unicode';
  font-style: normal;
  src: url('../../Common/assets/Lucida Sans Unicode.ttf');
}

form .MuiInputBase-input, form .MuiInputBase-root {
  background: white !important;
}