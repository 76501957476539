.badge {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Created {
    background-color: #eaefe0;
    color: #7ab800;
}

.CreationPending {
    background-color: #e5edf6;
    color: #2a6ebb;
}

.NotFound {
    background-color: #fbe5e0;
    color: #e52700;
}

.PendingRemoval {
    background-color: #f5eae0;
    color: #ff7900;
}

.Archived {
    background-color: #ebebeb;
    color: #6c6c6c;
}

.New {
    background-color: #ebebeb;
    color: #6c6c6c;
}