.layout {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.navigation {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    transition: width .75s;
    z-index: 2;
}

.navigationCollapsed {
    width: 60px;
    transition: width .75s;
    transition: .75s;

}


.mainContent {
    height: 100%;
    width: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 250px;
    transition: .75s;

}

.mainContentCollapsed {
    margin-left: 60px
}