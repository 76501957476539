$size: 28px;

.additionalTools {

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 12px;

    border-radius: 50%;
    outline: 1px solid #e8e8e8;
    width: $size;
    height: $size;
    color: #9e9e9e;
}