$blue-color: #2A6EBB !important;
.informationAlert {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #2A6EBB;

    &__title {
        color: black;
        font-weight: 700 !important;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    &__text {
        color: black;
        font-size: 14px;
        line-height: 143%;
    }
}