.selfOnboarding {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #2a6ebb;

  &__title {
    color: black;
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 150%;

    letter-spacing: 0.15px;
  }

  &__text {
    color: black;
    font-size: 14px;
    line-height: 143%;
  }
}
