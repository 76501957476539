.projectDetails {
    &__flaggedAlert {
        border: 1px solid #CC6100;
        width: 43%;
        margin-bottom: 32px;
        color: #663000;
        background-color: #fff1e5;

    }   
     &__flaggedAlertTitle {
        font-weight: bold;
        color: #663000;

    }

    &__packageDetailsTitle {
        margin-bottom: 32px;
    }
    &__footer {
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        background: white;
        margin-top: auto;
        display: flex;
        justify-content: flex-end;
        margin-right: 80px;
        outline: 1px solid #e0e0e0;
        // position: -webkit-sticky;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
}