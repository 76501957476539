.faqToolButton {
    &:hover {
        cursor: pointer;
    }
    padding: 12px;
    border: 1px solid transparent;
    background-color: transparent !important;

    transition: all .3s;
    &--selected {
        background-color: #ebf5eb !important;
        border: 1px solid #008200;
        border-radius: 4px;
        transition: all .3s;
    }
}