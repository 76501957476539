.menuContainer {
    background-color: #eee;
    height: 100%;

    border-right: 1px solid #e0e0e0;
}


.navigationElements {
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    justify-content: space-between;
    margin: 8px;
}

.activeLink {
    background-color: #ebf5eb !important;
    border-radius: 4px !important;
    span {
        color: #008200 !important;
    }

    svg {
        color: #008200;
    }
}

.link {
    height: 48px !important;
    max-height: 48px !important;
    text-overflow: ellipsis !important;

    white-space: nowrap;
    overflow: hidden !important;
}

.linkIcon {
    margin-left: -7.5px;
    transition: 1s;
}