.addUsers {
    
}

.tableWrapper {
  width: 100%;
  background-color: white;
  margin-bottom: 20px;
}

.userEmail {
  font-size: 12px;
}

.user{
  font-family: 'HEINEKEN Core', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.userName {
  @extend .user;
  color: rgba(0, 0, 0, 0.87);
}

.userHeiwayAcc {
  @extend .user;
}

input[name="email"], input[name="name"] {
  padding: 8px 8px 9px 8px;
}