.faqView {
    padding-top: 24px;
    width: 80%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding-bottom: 200px;

    input, :global(.MuiInputBase-input) {
        background-color: white;
    }
}

.actionButtons {
    margin-top: 50px;

    > button {
        margin-right: 20px;
    }
}


.topBar {
    display: flex;
    width: 94%;
    justify-content: space-between;
}