.toolDetails {

    &__tool {
        display: flex;
    }

    &__toolName {
        font-size: 14px !important;
        color: black !important;
    }
 

    &__info {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        justify-content: center;
    } 
}