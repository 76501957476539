:global(.ql-editor .ql-video) {
    display: block;
    max-width: 100%;
    height: 300px;
    width: 500px;

}

.createFaqView {
    padding-top: 24px;
    padding-left: 100px;
    width: 80%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding-bottom: 200px;

    input, :global(.MuiInputBase-input) {
        background-color: white;
    }
}

.actionButtons {
    margin-top: 50px;

    > button {
        margin-right: 20px;
    }
}