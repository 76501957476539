.tableWrapper {
    width: 100%;
    background-color: white;
    margin-bottom: 20px;

}

strong {
    font-family: HEINEKEN Core, serif;
    padding-right: 10px;
    padding-left: 10px;
}