.stepperWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.stepper {
    background: white;
    padding: 18px 80px;
    margin-bottom: 24px;

    &__content {
        margin-left: 80px;
    }

    &__footer {
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        background: white;
        margin-top: auto;
        display: flex;
        justify-content: flex-end;
        margin-right: 80px;
        outline: 1px solid #e0e0e0;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
    }

    &__footerButtons {
        margin-right: 80px;
    }
}