.package {
    width: 270px;
    height: 220px;
    margin-right: 24px;
    margin-bottom: 24px;


    background: #FFFFFF;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    &__description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }

    &__content {
        padding: 16px;
    }
    &__tools { 
        margin-left: 8px;
        margin-bottom: 16px;
     
        display: flex;
    }

    &__actionButtons {
        display: flex;
        justify-content: flex-end;
        margin-right: 16px;
        &:first-child {
            margin-right: 8px;

        }
    }
}