$tool-tile-width: 270px;
$tool-margin: 8px;
$icon-width: 40px;
$padding: 16px;

.toolTile {
    background: white;
    border-radius: 1.6;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-right: 24px;
    width: $tool-tile-width;
    height: 108px;

    padding: $padding;
    border-radius: 8px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

    display: flex;

    &__content {
        margin-left: $tool-margin;
        width: calc($tool-tile-width - $icon-width - (2 * $padding) - $tool-margin);

    }
    &__selection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
    }
    &__selected {
        box-shadow: 0px 0px 6px #008200;
        border: 1px solid #008200;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2E7D32;

        transition: box-shadow .3s;
    }
    &__toolName {
        font-size: 20 !important;
        line-height: 25px !important;
        white-space: nowrap;
        overflow: hidden;
    }
    &__capability {
        font-size: 12px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-transform: uppercase;
        letter-spacing: 0.7px !important;
        max-width: 100%;

    }

    &__capabilityWrapper {
        display: flex;
        svg {
            margin-left: auto;
        }
    }
}