
.details {
    &__topBarContainer {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-between;
        margin-right: 100px;

    }
}