.dialog {

    &__content {
        max-width: 1178px;
    }
    &__tools {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1200px;
    }
}