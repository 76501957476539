$blue-color: #2a6ebb !important;

.selfOnboarding {
  &__editBtn {
    color: $blue-color;
    border-color: $blue-color;
    margin-right: 10px;
    padding: 4px 10px !important;
  }
  &__closeBtn {
    color: $blue-color;
  }
}

.editSelfOnboarding {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
